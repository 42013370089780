import { getFunctions, httpsCallable } from 'firebase/functions';

const CACHE_DURATION = 1000 * 60 * 15; // 15 minutes
let weatherCache = {
  data: null,
  timestamp: null
};

export async function fetchWeather(zipCode) {
  try {
    // Check cache
    if (weatherCache.data && weatherCache.timestamp && 
        Date.now() - weatherCache.timestamp < CACHE_DURATION &&
        weatherCache.zipCode === zipCode) {
      return weatherCache.data;
    }

    if (!zipCode || !/^\d{5}$/.test(zipCode)) {
      throw new Error('Invalid ZIP code format');
    }

    const functions = getFunctions();
    const getWeather = httpsCallable(functions, 'getWeather');
    const result = await getWeather({ zipCode });
    
    if (!result.data) {
      throw new Error('No weather data received');
    }
    
    // Update cache
    weatherCache = {
      data: result.data,
      timestamp: Date.now(),
      zipCode
    };
    
    return result.data;
  } catch (error) {
    console.error('Weather API Error:', error);
    if (error.code === 'unauthenticated') {
      throw new Error('Please sign in to view weather data');
    } else if (error.code === 'invalid-argument') {
      throw new Error('Invalid ZIP code format');
    } else if (error.details?.message) {
      throw new Error(error.details.message);
    } else {
      throw new Error('Failed to fetch weather data');
    }
  }
} 