<template>
  <div class="profile-settings">
    <h4>Profile Settings</h4>
    
    <div class="settings-section">
      <h5>API Keys</h5>
      
      <div class="setting-item">
        <label>Clockify API Key</label>
        <div class="input-group">
          <input 
            type="password" 
            v-model="clockifyKey"
            placeholder="Enter your Clockify API key"
          />
          <button @click="saveClockifyKey">Save</button>
        </div>
        <small class="help-text">
          Current: {{ maskedClockifyKey }}
          <br>
          Get your API key from <a href="https://app.clockify.me/user/settings" target="_blank">Clockify Settings</a>
        </small>
      </div>

      <div class="setting-item">
        <label>Weather API Key</label>
        <div class="input-group">
          <input 
            type="password" 
            v-model="weatherKey"
            placeholder="Enter your OpenWeather API key"
          />
          <button @click="saveWeatherKey">Save</button>
        </div>
        <small class="help-text">
          Current: {{ maskedWeatherKey }}
        </small>
      </div>

      <div class="setting-item">
        <label>GitHub Username</label>
        <div class="input-group">
          <input 
            type="text" 
            v-model="githubUsername"
            placeholder="Enter your GitHub username (e.g., octocat)"
          />
          <button @click="saveGithubUsername">Save</button>
        </div>
        <small class="help-text">
          Current: {{ currentGithubUsername || 'Not set' }}
          <br>
          Enter your GitHub username to display your contribution graph
        </small>
      </div>
    </div>

    <div class="settings-section">
      <h5>Location Settings</h5>
      <div class="setting-item">
        <label>ZIP Code</label>
        <div class="input-group">
          <input 
            type="text" 
            v-model="zipCode"
            placeholder="Enter your ZIP code (e.g., 90210)"
            pattern="[0-9]{5}"
          />
          <button @click="saveZipCode">Save</button>
        </div>
        <small class="help-text">
          Current: {{ currentZipCode || 'Not set' }}
        </small>
      </div>
    </div>

    <div v-if="message" :class="['message', messageType]">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { getDatabase, ref as dbRef, set, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';

export default {
  name: 'ProfileSettings',
  data() {
    return {
      clockifyKey: '',
      weatherKey: '',
      githubUsername: '',
      message: '',
      messageType: 'success',
      currentGithubUsername: null,
      currentClockifyKey: null,
      currentWeatherKey: null,
      zipCode: '',
      currentZipCode: null,
    }
  },
  mounted() {
    this.loadUserSettings();
  },
  computed: {
    maskedWeatherKey() {
      const key = this.currentWeatherKey;
      return key ? `${key.slice(0, 4)}...${key.slice(-4)}` : 'Not set';
    },
    maskedClockifyKey() {
      const key = this.currentClockifyKey;
      return key ? `${key.slice(0, 4)}...${key.slice(-4)}` : 'Not set';
    }
  },
  methods: {
    async loadUserSettings() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const db = getDatabase();
        const userProfileRef = dbRef(db, `users/${user.uid}/profile`);
        try {
          const snapshot = await get(userProfileRef);
          if (snapshot.exists()) {
            const data = snapshot.val();
            this.currentGithubUsername = data.githubUsername || null;
            this.currentClockifyKey = data.clockifyKey || null;
            this.currentWeatherKey = data.weatherKey || null;
            this.currentZipCode = data.zipCode || null;
          }
        } catch (error) {
          console.error('Error loading user settings:', error);
        }
      }
    },
    async saveClockifyKey() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        this.showMessage('Please sign in to save settings', 'error');
        return;
      }

      try {
        const db = getDatabase();
        const userProfileRef = dbRef(db, `users/${user.uid}/profile`);
        
        // First get existing data
        const snapshot = await get(userProfileRef);
        const existingData = snapshot.exists() ? snapshot.val() : {};
        
        // Update with new data while preserving existing
        await set(userProfileRef, {
          ...existingData,
          clockifyKey: this.clockifyKey
        });

        this.currentClockifyKey = this.clockifyKey;
        this.clockifyKey = '';
        this.showMessage('Clockify API key saved successfully!', 'success');
        this.$emit('clockify-key-updated');
      } catch (error) {
        console.error('Error saving Clockify API key:', error);
        this.showMessage('Failed to save Clockify API key', 'error');
      }
    },
    async saveWeatherKey() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        this.showMessage('Please sign in to save settings', 'error');
        return;
      }

      try {
        const db = getDatabase();
        const userProfileRef = dbRef(db, `users/${user.uid}/profile`);
        
        // First get existing data
        const snapshot = await get(userProfileRef);
        const existingData = snapshot.exists() ? snapshot.val() : {};
        
        // Update with new data while preserving existing
        await set(userProfileRef, {
          ...existingData,
          weatherKey: this.weatherKey
        });

        this.currentWeatherKey = this.weatherKey;
        this.weatherKey = '';
        this.showMessage('Weather API key saved successfully!', 'success');
        this.$emit('weather-key-updated');
      } catch (error) {
        console.error('Error saving Weather API key:', error);
        this.showMessage('Failed to save Weather API key', 'error');
      }
    },
    async saveGithubUsername() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        this.showMessage('Please sign in to save settings', 'error');
        return;
      }

      try {
        const db = getDatabase();
        const userProfileRef = dbRef(db, `users/${user.uid}/profile`);
        
        // First get existing data
        const snapshot = await get(userProfileRef);
        const existingData = snapshot.exists() ? snapshot.val() : {};
        
        // Update with new data while preserving existing
        await set(userProfileRef, {
          ...existingData,
          githubUsername: this.githubUsername
        });

        this.currentGithubUsername = this.githubUsername;
        this.githubUsername = '';
        this.showMessage('GitHub username saved successfully!', 'success');
        this.$emit('github-username-updated');
      } catch (error) {
        console.error('Error saving GitHub username:', error);
        this.showMessage('Failed to save GitHub username', 'error');
      }
    },
    validateZipCode(zipCode) {
      const zipRegex = /^\d{5}$/;
      if (!zipRegex.test(zipCode)) {
        this.showMessage('Please enter a valid 5-digit ZIP code', 'error');
        return false;
      }
      return true;
    },
    async saveZipCode() {
      if (!this.validateZipCode(this.zipCode)) return;
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        this.showMessage('Please sign in to save settings', 'error');
        return;
      }

      try {
        const db = getDatabase();
        const userProfileRef = dbRef(db, `users/${user.uid}/profile`);
        
        const snapshot = await get(userProfileRef);
        const existingData = snapshot.exists() ? snapshot.val() : {};
        
        await set(userProfileRef, {
          ...existingData,
          zipCode: this.zipCode
        });

        this.currentZipCode = this.zipCode;
        this.zipCode = '';
        this.showMessage('ZIP code saved successfully!', 'success');
        this.$emit('zip-code-updated');
      } catch (error) {
        console.error('Error saving ZIP code:', error);
        this.showMessage('Failed to save ZIP code', 'error');
      }
    },
    showMessage(text, type = 'success') {
      this.message = text;
      this.messageType = type;
      setTimeout(() => {
        this.message = '';
      }, 3000);
    }
  }
}
</script>

<style scoped>
.profile-settings {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.settings-section {
  margin-top: 24px;
}

.settings-section h5 {
  color: var(--heading-color);
  font-size: 1rem;
  margin-bottom: 16px;
  font-weight: 600;
}

.setting-item {
  margin-bottom: 24px;
}

.setting-item label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--text-color);
}

.input-group {
  display: flex;
  gap: 12px;
}

input {
  flex-grow: 1;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.2s ease;
}

input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 1px var(--primary-color);
}

button {
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: #524ccc;
}

.help-text {
  display: block;
  margin-top: 6px;
  font-size: 12px;
  color: var(--text-color);
  opacity: 0.8;
}

.help-text a {
  color: var(--primary-color);
  text-decoration: none;
}

.help-text a:hover {
  text-decoration: underline;
}

.message {
  margin-top: 24px;
  padding: 12px 16px;
  border-radius: 6px;
  font-size: 14px;
}

.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Dark mode styles */
:global(.dark-mode) .profile-settings {
  background-color: #132F4C;
  color: #fff;
}

:global(.dark-mode) .settings-section h5 {
  color: #fff;
}

:global(.dark-mode) .setting-item label {
  color: #fff;
}

:global(.dark-mode) input {
  background-color: #0A1929;
  color: #fff;
  border-color: #1E3A5F;
}

:global(.dark-mode) input:focus {
  border-color: #60a5fa;
  box-shadow: 0 0 0 1px #60a5fa;
}

:global(.dark-mode) .help-text {
  color: #aaa;
}

:global(.dark-mode) .help-text a {
  color: #60a5fa;
}

:global(.dark-mode) .success {
  background-color: #064e3b;
  color: #a7f3d0;
  border-color: #065f46;
}

:global(.dark-mode) .error {
  background-color: #7f1d1d;
  color: #fecaca;
  border-color: #991b1b;
}
</style> 