<template>
  <div class="clockify-timer">
    <h4>Time Tracking</h4>
    <div class="timer-input">
      <div class="input-group">
        <input 
          type="text" 
          v-model="description" 
          placeholder="What are you working on?"
          class="task-input"
        />
        <select v-model="selectedProjectId" class="project-select">
          <option value="">Select Project</option>
          <option v-for="project in projects" :key="project.id" :value="project.id">
            {{ project.name }}
          </option>
        </select>
      </div>
      <button 
        @click="toggleTimer" 
        :class="{ 'running': isTimerRunning }"
        :disabled="!selectedProjectId"
      >
        {{ isTimerRunning ? 'Stop' : 'Start' }}
      </button>
    </div>
    <div v-if="isTimerRunning" class="timer-display">
      Running: {{ formatDuration(elapsedTime) }}
    </div>
    <div v-if="error" class="error-message">
      {{ error }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getDatabase, ref as dbRef, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';

export default {
  name: 'ClockifyTimer',
  data() {
    return {
      description: '',
      isTimerRunning: false,
      startTime: null,
      elapsedTime: 0,
      timerInterval: null,
      error: null,
      currentTimeEntry: null,
      workspaceId: null,
      projects: [],
      selectedProjectId: ''
    }
  },
  async mounted() {
    await this.initializeWorkspace();
  },
  methods: {
    async getApiKey() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const db = getDatabase();
        const userProfileRef = dbRef(db, `users/${user.uid}/profile`);
        try {
          const snapshot = await get(userProfileRef);
          if (snapshot.exists() && snapshot.val().clockifyKey) {
            return snapshot.val().clockifyKey;
          }
        } catch (error) {
          console.error('Error getting API key:', error);
        }
      }
      return process.env.VUE_APP_CLOCKIFY_API_KEY;
    },
    async initializeWorkspace() {
      try {
        const apiKey = await this.getApiKey();
        if (!apiKey) {
          this.error = 'Please set your Clockify API key in Profile Settings';
          return;
        }

        const response = await axios.get('https://api.clockify.me/api/v1/workspaces', {
          headers: {
            'X-Api-Key': apiKey
          }
        });
        
        if (response.data && response.data.length > 0) {
          this.workspaceId = response.data[0].id;
          await this.fetchProjects();
        } else {
          this.error = 'No workspace found';
        }
      } catch (err) {
        this.error = 'Failed to initialize Clockify';
        console.error('Clockify API Error:', err);
      }
    },
    async fetchProjects() {
      try {
        const apiKey = await this.getApiKey();
        const response = await axios.get(`https://api.clockify.me/api/v1/workspaces/${this.workspaceId}/projects`, {
          headers: {
            'X-Api-Key': apiKey
          }
        });
        this.projects = response.data;
      } catch (err) {
        this.error = 'Failed to fetch projects';
        console.error('Clockify API Error:', err);
      }
    },
    async toggleTimer() {
      if (!this.isTimerRunning) {
        await this.startTimer();
      } else {
        await this.stopTimer();
      }
    },
    async startTimer() {
      if (!this.workspaceId || !this.selectedProjectId) {
        this.error = 'Please select a project';
        return;
      }

      try {
        const apiKey = await this.getApiKey();
        const response = await axios.post(`https://api.clockify.me/api/v1/workspaces/${this.workspaceId}/time-entries`, {
          start: new Date().toISOString(),
          description: this.description,
          projectId: this.selectedProjectId,
          billable: true
        }, {
          headers: {
            'X-Api-Key': apiKey,
            'Content-Type': 'application/json'
          }
        });

        this.currentTimeEntry = response.data;
        this.isTimerRunning = true;
        this.startTime = new Date();
        this.timerInterval = setInterval(this.updateElapsedTime, 1000);
      } catch (err) {
        this.error = 'Failed to start timer: ' + (err.response?.data?.message || err.message);
        console.error('Clockify API Error:', err);
      }
    },
    async stopTimer() {
      if (!this.workspaceId || !this.currentTimeEntry) {
        this.error = 'No active timer';
        return;
      }

      try {
        const apiKey = await this.getApiKey();
        await axios.put(`https://api.clockify.me/api/v1/workspaces/${this.workspaceId}/time-entries/${this.currentTimeEntry.id}`, {
          start: this.currentTimeEntry.timeInterval.start,
          end: new Date().toISOString(),
          billable: true,
          projectId: this.selectedProjectId,
          description: this.description
        }, {
          headers: {
            'X-Api-Key': apiKey,
            'Content-Type': 'application/json'
          }
        });

        this.isTimerRunning = false;
        clearInterval(this.timerInterval);
        this.elapsedTime = 0;
        this.currentTimeEntry = null;
        this.description = '';
        this.selectedProjectId = '';
      } catch (err) {
        this.error = 'Failed to stop timer: ' + (err.response?.data?.message || err.message);
        console.error('Clockify API Error:', err);
      }
    },
    updateElapsedTime() {
      if (this.startTime) {
        this.elapsedTime = Math.floor((new Date() - this.startTime) / 1000);
      }
    },
    formatDuration(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
  },
  beforeUnmount() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }
}
</script>

<style scoped>
.clockify-timer {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.clockify-timer h4 {
  margin-top: 0;
  margin-bottom: 16px;
}

.timer-input {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.task-input {
  flex-grow: 1;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  background-color: var(--background-color);
  color: var(--text-color);
}

.timer-display {
  font-size: 14px;
  color: var(--text-color);
  margin-top: 10px;
}

.error-message {
  color: #dc3545;
  font-size: 12px;
  margin-top: 8px;
}

/* Dark mode styles */
:global(.dark-mode) .clockify-timer {
  background-color: #0A1929;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

:global(.dark-mode) .task-input {
  background-color: #132F4C;
  color: #fff;
  border-color: #1E3A5F;
}

:global(.dark-mode) .task-input:focus {
  border-color: #60a5fa;
  box-shadow: 0 0 0 1px #60a5fa;
}

:global(.dark-mode) .timer-display {
  color: #fff;
}

.input-group {
  display: flex;
  flex-grow: 1;
  gap: 10px;
}

.project-select {
  min-width: 150px;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  background-color: var(--background-color);
  color: var(--text-color);
}

:global(.dark-mode) .project-select {
  background-color: #132F4C;
  color: #fff;
  border-color: #1E3A5F;
}

:global(.dark-mode) .project-select:focus {
  border-color: #60a5fa;
  box-shadow: 0 0 0 1px #60a5fa;
}

button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #4b44c9;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

:global(.dark-mode) button {
  background-color: #2563eb;
}

:global(.dark-mode) button:hover {
  background-color: #1d4ed8;
}
</style> 